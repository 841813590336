import axios from "axios";
import { Constants } from "../../constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { CryptoLib } from "../../lib/cryptoLib";
// import cloudinary from "cloudinary/lib/cloudinary"
// import { FirebaseImageDataSource } from "./imageDataSource";

export class CloudinaryDatasource {
  static uploadImage = createAsyncThunk(
    "uploadImage",
    async (formData: any) => {
      return axios
        .post(Constants.cloudinaryImageUploadUrl, formData)
        .then((response) => {
          return {
            filename: formData.get("file").name,
            url: response.data.url,
            publicId: response.data.public_id,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  );

  static deleteImage = createAsyncThunk(
    "deleteImage",
    async (publicId: string) => {
      //   const timestamp = new Date().getTime();
      //   const signature = CryptoLib.generateSHA1(
      //     CryptoLib.generateSignature(publicId, Constants.cloudinaryAPISecret)
      //   );
      //   axios
      //     .post(Constants.cloudinaryImageDeleteUrl, {
      //       public_id: publicId,
      //       signature: signature,
      //       api_key: Constants.cloudinaryAPIKey,
      //       timestamp: timestamp,
      //     })
      //     .then((response) => {
      //       console.log("Image deleted from cloudinary: ", response);
      //     })
      //     .catch((error) => {
      //       console.error("Unable to delete image: ", error);
      //     });
    }
  );

  static uploadVideo = createAsyncThunk(
    "uploadVideo",
    async (formData: any) => {
      return axios
        .post(Constants.cloudinaryVideoUploadUrl, formData)
        .then((response) => {
          return {
            filename: formData.get("file").name,
            url: response.data.url,
            publicId: response.data.public_id,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  );
}
