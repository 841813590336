import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";

export class FirebaseNoteDataSource {
  static writeNote = async (text: string) => {
    const docRef = doc(db, "note", "content");
    await updateDoc(docRef, { text });
  };

  static getCurrentNote = async () => {
    // const imagesCollectionRef = collection(db, "currentImage");
    const currentNoteRef = doc(db, "note", "content");
    const snap = await getDoc(currentNoteRef);
    return snap.data()!.text;
    // console.log(snap.data());
  };
}
