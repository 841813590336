import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";
import { FileInfo } from "../../domain/entities/FileInfo";
import { store } from "../../presentation/redux/store";
import { setImageList } from "../../presentation/redux/slices/imageSlice";
import { setVideoList } from "../../presentation/redux/slices/videoSlice";

export class FirebaseVideoDataSource {
  static getVideos = async () => {
    const videosCollectionRef = collection(db, "videos");
    const querySnapshot = await getDocs(videosCollectionRef);
    const videoList: FileInfo[] = [];
    querySnapshot.forEach((doc: any) => {
      const { filename, url, publicId } = doc.data();
      const image: FileInfo = { id: doc.id, filename, url, publicId };
      videoList.push(image);
    });
    store.dispatch(setVideoList(videoList));
  };

  static deleteVideo = async (id: string) => {
    await deleteDoc(doc(db, "videos", id));
  };

  static writeVideoFileInfo = async (fileInfo: FileInfo) => {
    const videoCollectionRef = collection(db, "videos");
    await addDoc(videoCollectionRef, fileInfo);
  };

  //   static writeImages = async (imageList: FileInfo[]) => {
  //     const imagesCollectionRef = collection(db, "images");
  //     // const imageListDocRef =  doc(db, "images", "MosgJNkNQduyIY0JlR6D");
  //     imageList.forEach(async (image) => {
  //       await addDoc(imagesCollectionRef, image);
  //     });
  //   };

  static getCurrentVideo = async () => {
    const currentVideoRef = doc(db, "currentVideo", "Video");
    const snap = await getDoc(currentVideoRef);
    return snap.data();
  };

  static getVideoById = async (id: string) => {
    const videoRef = doc(db, "videos", id);
    const snap = await getDoc(videoRef);
    return snap.data();
  };
}
