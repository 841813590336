import { collection, doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../connection/firebaseConfig";
import { FirebaseAppDataSource } from "../../data/firebase/appDatasource";
import { FirebaseScheduleDataSource } from "../../data/firebase/scheduleDatasource";
import { Schedule } from "../../domain/entities/Schedule";
import { FirebaseImageDataSource } from "../../data/firebase/imageDataSource";
import { FirebaseVideoDataSource } from "../../data/firebase/videoDatasource";
import RunScheduleContainer from "./RunScheduleContainer";
import { FilePlaying } from "../../domain/FIlePlaying";

const ClientRunScheduleContainer = () => {
  // const [schedule, setSchedule] = useState();

  // const getCurrentSchedule = async () => {
  //   const currentSchedule =
  //     await FirebaseScheduleDataSource.getCurrentSchedule();
  //   console.log(currentSchedule);
  //   // if (currentSchedule)
  //   // const schedule = currentSchedule
  //   //   ? await FirebaseScheduleDataSource.getScheduleById(currentSchedule.id)
  //   //   : null;
  //   // console.log(schedule);
  // };

  // useEffect(() => {
  //   getCurrentSchedule();
  // }, []);
  const [file, setFile] = useState<FilePlaying>({ type: "Image", url: "" });

  // let timer: NodeJS.Timer;

  // const getCurrentSchedule = async () => {
  //   const currentSchedule =
  //     (await FirebaseScheduleDataSource.getCurrentSchedule()) as Schedule;
  //   timer = setInterval(async () => {
  //     let time = new Date();
  //     const h = time.getHours();
  //     const m = time.getMinutes();
  //     const s = time.getSeconds();
  //     console.log(1);

  //     for (let i = 0; i < currentSchedule.items.length; i++) {
  //       const t = currentSchedule.items[i].time
  //         .split(":")
  //         .map((ts) => parseInt(ts));

  //       if (h === t[0] && m === t[1] && s === 0) {
  //         const type = currentSchedule.items[i].type;
  //         const f =
  //           type === "Image"
  //             ? await FirebaseImageDataSource.getImageById(
  //                 currentSchedule.items[i].file
  //               )
  //             : await FirebaseVideoDataSource.getVideoById(
  //                 currentSchedule.items[i].file
  //               );

  //         setFile({ type, url: f?.url });
  //         // console.log(image);
  //         // dispatch(setRunFile({ type, url: f?.url }));
  //         break;
  //       }
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    // getCurrentSchedule();
    // return () => clearInterval(timer);
    const queryApp = collection(db, "currentFile");
    onSnapshot(queryApp, (snapshot) => {
      snapshot.docs.map((document: any) => {
        setFile(document.data());
        // setVideo(document.data());
        // const data = document.data();
        // if (data.type === "Video") setFile(data);
      });
    });
  }, []);

  return <RunScheduleContainer file={file} />;
};

export default ClientRunScheduleContainer;
