import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { db } from "../../connection/firebaseConfig";

const toolbarOptions: any = null;

const module = {
  toolbar: toolbarOptions,
};

const ClientNoteContainer = () => {
  const [value, setValue] = useState("");

  useEffect(() => {
    const queryApp = collection(db, "note");
    onSnapshot(queryApp, (snapshot) => {
      snapshot.docs.map((document) => {
        // console.log(document.data());
        setValue(document.data().text);
      });
    });
  }, []);

  return (
    <div className="w-screen h-screen">
      <ReactQuill
        value={value}
        theme="snow"
        // ref={quillRef}
        className="h-[90%]"
        modules={module}
      />
    </div>
  );
};

export default ClientNoteContainer;
