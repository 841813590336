export class Constants {
  //   static serverUrl = "http://localhost:5000";
  static serverUrl = "https://sharing-dashboard-node-server.vercel.app/";
  static cloudinaryCloudName = "dc1jtk3jy";
  static cloudinaryAPIKey = "557829485954349";
  static cloudinaryAPISecret = "nggwr9D9NaLNFhg_RdgtlBluLDM";
  static cloudinaryImageUploadUrl = `https://api.cloudinary.com/v1_1/${this.cloudinaryCloudName}/image/upload`;
  static cloudinaryImageDeleteUrl = `https://api.cloudinary.com/v1_1/${this.cloudinaryCloudName}/image/destroy`;
  static cloudinaryVideoUploadUrl = `https://api.cloudinary.com/v1_1/${this.cloudinaryCloudName}/video/upload`;
  static cloudinaryVideoDeleteUrl = `https://api.cloudinary.com/v1_1/${this.cloudinaryCloudName}/video/destroy`;
  static cloudinaryVideoFolder = "sharing-dashboard-videos";
  static pages = [
    "/adminHome",
    "/adminVideo",
    "/adminImage",
    "/adminNote",
    "/adminRunSchedule",
    "/adminCreateSchedule",
  ];
}
