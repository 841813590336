import React, { useState } from "react";
import ScheduleItemForm from "./ScheduleItemForm";
import ScheduleItemList from "./ScheduleItemList";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import ScheduleList from "./ScheduleList";
import { FirebaseScheduleDataSource } from "../../data/firebase/scheduleDatasource";
import { setSchedule } from "../redux/slices/scheduleSlice";

const CreateScheduleContainer = () => {
  const scheduleState = useAppSelector((state) => state.scheduleReducer);
  const dispatch = useAppDispatch();
  // const [scheduleName, setScheduleName] = useState("");

  const onSaveScheduleClick = () => {
    if (scheduleState.schedule.name === "")
      return alert("Enter name for this schedule, please!");
    if (scheduleState.schedule.items.length === 0)
      return alert("Enter at least one schedule item, please!");
    const alreadyExist = scheduleState.scheduleList.filter(
      (schedule) => schedule.name === scheduleState.schedule.name
    )[0];
    if (alreadyExist)
      return alert(
        "Schedule Name already exist, choose another name, or delete another one, please!"
      );
    FirebaseScheduleDataSource.createSchedule(scheduleState.schedule);
    FirebaseScheduleDataSource.getSchedules();

    // console.log(scheduleState.schedule);
  };

  const onInputScheduleNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(e.target.value);
    dispatch(setSchedule({ ...scheduleState.schedule, name: e.target.value }));
  };

  return (
    <div className="w-[100%] h-[100%] flex gap-1">
      <div className="w-[75%] border-2 p-1">
        {/* Title Create Schedule*/}
        <div className="bg-black text-white p-2 h-[7%] flex items-center">
          Create Schedule
        </div>

        {/* inputName  */}
        <div className="w-full flex items-center p-1 h-[13%]">
          <span className="w-40">Schedule Name</span>
          <input
            type="text"
            className="py-2 mx-2 w-full border-2"
            value={scheduleState.schedule.name}
            onChange={onInputScheduleNameChange}
            // value={scheduleName}
            // onChange={(e) => setScheduleName(e.target.value)}
          />
          <button
            className="p-2 bg-green-900 text-white w-36"
            onClick={onSaveScheduleClick}
          >
            Save
          </button>
        </div>

        <div className="w-full h-[80%] flex gap-1">
          <ScheduleItemForm classname="w-[30%] border-2 p-1 flex flex-col gap-1" />
          <ScheduleItemList classname="w-[70%] border-2" />
        </div>
      </div>
      <div className="w-[25%] border-2 p-1">
        <div className="bg-black text-white p-2 h-[7%] flex items-center">
          Schedule List
        </div>
        <ScheduleList classname="cw-full flex flex-col gap-1" />
      </div>
    </div>
  );
};

export default CreateScheduleContainer;

// import { useRef, useState } from "react";
// import { useAppSelector } from "../redux/hooks";
// import { TimeUtils } from "../../utils/timeUtils";
// import { useDispatch } from "react-redux";
// import {
//   insertSchedule,
//   setName,
//   setSchedule,
// } from "../redux/slices/scheduleSlice";
// import { FaEdit, FaShare, FaTrash } from "react-icons/fa";
// import { FirebaseScheduleDataSource } from "../../data/firebase/scheduleDatasource";
// import { Schedule } from "../../domain/entities/Schedule";
// import { ScheduleItem } from "../../domain/entities/ScheduleItem";

// const CreateScheduleContainer = () => {
//   const appState = useAppSelector((state) => state.appReducer);
//   const scheduleState = useAppSelector((state) => state.scheduleReducer);
//   const dispatch = useDispatch();
//   const imageState = useAppSelector((state) => state.imageReducer);
//   const videoState = useAppSelector((state) => state.videoReducer);

//   //   const [showModal, setShowModal] = useState(true);
//   const selectTypeRef = useRef<HTMLSelectElement>(null);
//   const inputTimeRef = useRef<HTMLInputElement>(null);
//   const selectFileRef = useRef<HTMLSelectElement>(null);
//   const inputScheduleNameRef = useRef<HTMLInputElement>(null);
//   // const scheduleDbRef = collection(db, "schedules");
//   const [files, setFiles] = useState(imageState.imageList);
//   const [time, setTime] = useState<string>(TimeUtils.DateToTimeHHMM());
//   const [scheduleItem, setScheduleItem] = useState<ScheduleItem>({
//     time: "",
//     type: "",
//     file: "",
//   });
//   const [schedule, setSchedule] = useState<Schedule>({
//     id: "",
//     name: "",
//     items: [],
//   });

//   const onSelectType = () => {
//     if (selectTypeRef.current!.value === "Video") {
//       setFiles(videoState.videoList);
//     } else {
//       setFiles(imageState.imageList);
//     }
//   };

//   const onBtnAddScheduleItemClick = () => {
//     if (inputTimeRef.current?.value === "") return alert("Fill Time please!");
//     const timeExist =
//       schedule.items.filter((item) => {
//         return item.time === time;
//       }).length > 0;
//     if (timeExist)
//       return alert(
//         "Time already exist, choose another time, or delete existing time"
//       );

//     // setSchedule({})

//     // console.log(
//     //   time,
//     //   selectTypeRef.current!.value,
//     //   selectFileRef.current!.value
//     // );

//     // dispatch(
//     //   insertSchedule({
//     //     time,
//     //     type: selectTypeRef.current!.value,
//     //     file: selectFileRef.current!.value,
//     //   })
//     // );
//   };

//   const onSaveScheduleClick = async () => {
//     if (inputScheduleNameRef.current!.value === "")
//       return alert("Enter name for this schedule, please!");
//     if (scheduleState.items.length === 0)
//       return alert("Enter at least one schedule item, please!");
//     const alreadyExist = appState.scheduleList.filter(
//       (schedule) => schedule.name === inputScheduleNameRef.current!.value
//     )[0];
//     if (alreadyExist)
//       return alert(
//         "Schedule Name already exist, choose another name, or delete another one, please!"
//       );
//     FirebaseScheduleDataSource.createSchedule(schedule);
//     FirebaseScheduleDataSource.getSchedules();
//   };

//   const onDeleteScheduleItem = async (scheduleItem: ScheduleItem) => {
//     const newSchedule = schedule.items.filter(
//       (item) => item.time !== scheduleItem.time
//     );
//     console.log(newSchedule);
//     // dispatch(setSchedule(newSchedule));
//   };

//   const onDeleteScheduleClick = async (id: string) => {
//     console.log(id);
//     FirebaseScheduleDataSource.deleteSchedule(id);
//     FirebaseScheduleDataSource.getSchedules();
//   };

//   return (
//     <>
//       <div className="w-[100%] h-[100%] flex border-2 p-1 z-50 gap-1">
//         <div className="w-[75%] border-2 p-1">
//           <div className="bg-black text-white p-2 h-[10%]">Create Schedule</div>

//           <div className="p-2 flex items-center h-[15%]">
//             <span className="w-48">Schedule Name</span>
//             <input
//               type="text"
//               className="py-2 mx-2 w-full border-2"
//               ref={inputScheduleNameRef}
//               value={schedule.name}
//               onChange={() =>
//                 setSchedule({
//                   ...schedule,
//                   name: inputScheduleNameRef.current!.value,
//                 })
//               }
//             />
//             <button
//               className="p-2 bg-green-900 text-white w-36 ml-5"
//               onClick={onSaveScheduleClick}
//             >
//               Save
//             </button>
//           </div>

//           <div className="w-full h-[75%] flex gap-2">
//             <div className="w-[30%] flex flex-col border-2 p-2 gap-3">
//               <div className="flex items-center">
//                 <span className="w-24">Time</span>
//                 <input
//                   type="time"
//                   className="p-1 border-2 w-full"
//                   onChange={() => setTime(inputTimeRef.current!.value)}
//                   ref={inputTimeRef}
//                   value={time}
//                 />
//               </div>
//               <div className="flex items-center">
//                 <span className="w-24">Type</span>
//                 <select
//                   ref={selectTypeRef}
//                   className="p-1 border-2 w-full"
//                   onChange={onSelectType}
//                 >
//                   <option value="Image">Image</option>
//                   <option value="Video">Video</option>
//                 </select>
//               </div>
//               <div className="flex items-center">
//                 <span className="w-24">File</span>
//                 <select className="p-1 border-2 w-full" ref={selectFileRef}>
//                   {files.map((file, index) => {
//                     return (
//                       <option value={file.filename} key={index}>
//                         {file.filename}
//                       </option>
//                     );
//                   })}
//                 </select>
//               </div>
//               <button
//                 className="p-2 bg-blue-900 text-white w-full"
//                 onClick={onBtnAddScheduleItemClick}
//               >
//                 Add Schedule
//               </button>
//             </div>

//             <div className="w-[70%] border-2">
//               <table className="table-auto px-2 max-h-full w-full">
//                 <thead className="text-left w-full sticky top-0 bg-blue-950 text-white">
//                   <tr className="px-3">
//                     <th className="pl-2">#</th>
//                     <th>TIME</th>
//                     <th>TYPE</th>
//                     <th>URL</th>
//                     <th className="text-center">ACTIONS</th>
//                   </tr>
//                 </thead>
//                 <tbody className="text-left w-full border-2">
//                   {scheduleState.items.map((schedule, index) => {
//                     return (
//                       <tr key={index}>
//                         <td className="border-2 p-1">{index + 1}</td>
//                         <td className="border-2 p-1">{schedule.time}</td>
//                         <td className="border-2 p-1">{schedule.type}</td>
//                         <td className="border-2 p-1">{schedule.file}</td>
//                         <td className="border-2 p-1">
//                           <div className="flex justify-evenly">
//                             <FaTrash
//                               className="text-red-600 cursor-pointer text-xl hover:border-2"
//                               onClick={() => onDeleteScheduleItem(schedule)}
//                             />
//                             <FaEdit className="text-yellow-600 cursor-pointer text-xl hover:border-2" />
//                           </div>
//                         </td>
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>

//         <div className="w-[25%] border-2 p-1">
//           <div className="bg-black text-white p-2 h-[10%] mb-1">
//             Schedule List
//           </div>
//           <div className="w-full flex flex-col gap-1">
//             {appState.scheduleList.map((schedule, index) => {
//               return (
//                 <div
//                   key={index}
//                   className="w-full border-2 p-1 flex items-center"
//                 >
//                   <div className="w-[80%]">{schedule.name}</div>
//                   <div className="w-[20%] flex gap-4">
//                     <FaTrash
//                       className="cursor-pointer"
//                       onClick={() => onDeleteScheduleClick(schedule.id)}
//                     />
//                     <FaShare className="cursor-pointer" />
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CreateScheduleContainer;

// import { useEffect, useRef, useState } from "react";
// import { useAppSelector } from "../redux/hooks";
// import { TimeUtils } from "../../utils/timeUtils";
// import { useDispatch } from "react-redux";
// import { insertSchedule, setName } from "../redux/slices/scheduleSlice";
// import { FaEdit, FaTrash } from "react-icons/fa";
// import { addDoc, collection } from "firebase/firestore";
// import { db } from "../../connection/firebaseConfig";

// const CreateScheduleContainer = () => {
//   const scheduleState = useAppSelector((state) => state.scheduleReducer);
//   const dispatch = useDispatch();
//   const imageState = useAppSelector((state) => state.imageReducer);
//   const videoState = useAppSelector((state) => state.videoReducer);
//   const [files, setFiles] = useState(imageState.imageList);
//   //   const [showModal, setShowModal] = useState(true);
//   const selectTypeRef = useRef<HTMLSelectElement>(null);
//   const inputTimeRef = useRef<HTMLInputElement>(null);
//   const selectFileRef = useRef<HTMLSelectElement>(null);
//   const inputScheduleNameRef = useRef<HTMLInputElement>(null);
//   const scheduleDbRef = collection(db, "schedules");

//   const [time, setTime] = useState<string>(TimeUtils.DateToTimeHHMM());

//   const onSelectType = () => {
//     if (selectTypeRef.current!.value === "Video") {
//       setFiles(videoState.videoList);
//     } else {
//       setFiles(imageState.imageList);
//     }
//   };

//   const onBtnAddScheduleClick = () => {
//     if (inputTimeRef.current?.value === "") return alert("Fill Time please!");
//     const timeExist =
//       scheduleState.items.filter((item) => {
//         return item.time === time;
//       }).length > 0;
//     // if (timeExist)
//     //   return alert(
//     //     "Time already exist, choose another time, or delete existing time"
//     //   );

//     console.log(
//       time,
//       selectTypeRef.current!.value,
//       selectFileRef.current!.value
//     );

//     dispatch(
//       insertSchedule({
//         time,
//         type: selectTypeRef.current!.value,
//         file: selectFileRef.current!.value,
//       })
//     );
//   };

//   const onSaveScheduleClick = async () => {
//     if (inputScheduleNameRef.current!.value === "")
//       return alert("Enter name for this schedule, please!");
//     console.log(scheduleState);

//     await addDoc(scheduleDbRef, scheduleState);
//   };

//   return (
//     <>
//       <div className="w-[100%] h-[100%] flex flex-col border-2 p-1 z-50">
//         <div className="bg-black text-white p-2 h-[10%]">Create Schedule</div>

//         <div className="p-2 flex items-center h-[15%]">
//           <span className="w-36">Schedule Name</span>
//           <input
//             type="text"
//             className="py-2 mx-2 w-full border-2"
//             ref={inputScheduleNameRef}
//             onChange={() =>
//               dispatch(setName(inputScheduleNameRef.current!.value))
//             }
//           />
//           <button
//             className="p-2 bg-green-900 text-white w-36 ml-5"
//             onClick={onSaveScheduleClick}
//           >
//             Save
//           </button>
//         </div>

//         <div className="w-full h-full flex gap-2">
//           <div className="w-[30%] flex flex-col border-2 p-2 gap-3">
//             <div className="flex items-center">
//               <span className="w-24">Time</span>
//               <input
//                 type="time"
//                 className="p-1 border-2 w-full"
//                 onChange={() => setTime(inputTimeRef.current!.value)}
//                 ref={inputTimeRef}
//                 value={time}
//               />
//             </div>
//             <div className="flex items-center">
//               <span className="w-24">Type</span>
//               <select
//                 ref={selectTypeRef}
//                 className="p-1 border-2 w-full"
//                 onChange={onSelectType}
//               >
//                 <option value="Image">Image</option>
//                 <option value="Video">Video</option>
//               </select>
//             </div>
//             <div className="flex items-center">
//               <span className="w-24">File</span>
//               <select className="p-1 border-2 w-full" ref={selectFileRef}>
//                 {files.map((file, index) => {
//                   return (
//                     <option value={file.filename} key={index}>
//                       {file.filename}
//                     </option>
//                   );
//                 })}
//               </select>
//             </div>
//             <button
//               className="p-2 bg-blue-900 text-white w-full"
//               onClick={onBtnAddScheduleClick}
//             >
//               Add Schedule
//             </button>
//           </div>
//           <div className="w-[70%] border-2">
//             <table className="table-auto px-2 max-h-full w-full">
//               <thead className="text-left w-full sticky top-0 bg-blue-950 text-white">
//                 <tr className="px-3">
//                   <th className="pl-2">#</th>
//                   <th>TIME</th>
//                   <th>TYPE</th>
//                   <th>URL</th>
//                   <th className="text-center">ACTIONS</th>
//                 </tr>
//               </thead>
//               <tbody className="text-left w-full border-2">
//                 {scheduleState.items.map((schedule, index) => {
//                   return (
//                     <tr key={index}>
//                       <td className="border-2 p-1">{index + 1}</td>
//                       <td className="border-2 p-1">{schedule.time}</td>
//                       <td className="border-2 p-1">{schedule.type}</td>
//                       <td className="border-2 p-1">{schedule.file}</td>
//                       <td className="border-2 p-1">
//                         <div className="flex justify-evenly">
//                           <FaTrash className="text-red-600 cursor-pointer text-xl hover:border-2" />
//                           <FaEdit className="text-yellow-600 cursor-pointer text-xl hover:border-2" />
//                         </div>
//                       </td>
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CreateScheduleContainer;

// {
//   /* <>
//   <div className="w-[90%] h-[100%] flex flex-col border-2 p-1 z-50">
//     <div className="bg-black text-white p-2 h-[10%]">Create Schedule</div>
//     <div className="p-2 flex items-center h-[15%]">
//       <span className="w-36">Schedule Name</span>
//       <input
//         type="text"
//         className="py-2 mx-2 w-full border-2"
//         ref={inputScheduleNameRef}
//       />
//     </div>
//     <div className="w-full h-[75%] flex flex-col border-2 p-2">
//       <div className="h-[10%] flex mb-2 items-center">
//         <span className="w-24">Time</span>
//         <input
//           type="time"
//           className="p-1 border-2 w-48"
//           onChange={() => setTime(inputTimeRef.current!.value)}
//           ref={inputTimeRef}
//           value={time}
//         />
//         <button
//           className="p-2 bg-blue-900 text-white w-36 ml-5"
//           onClick={onBtnAddScheduleClick}
//         >
//           Add Schedule
//         </button>
//       </div>
//       <div className="h-[10%] flex mb-4 items-center">
//         <span className="w-24">Type</span>
//         <select
//           ref={selectTypeRef}
//           className="p-1 border-2 w-48"
//           onChange={onSelectType}
//           //   value={type}
//         >
//           <option value="Image">Image</option>
//           <option value="Video">Video</option>
//         </select>
//       </div>
//       <div className="h-[10%] flex mb-4 items-center">
//         <span className="w-24">File</span>
//         <select className="p-1 border-2 w-48" ref={selectFileRef}>
//           {files.map((file, index) => {
//             return (
//               <option value={file} key={index}>
//                 {file}
//               </option>
//             );
//           })}
//         </select>
//       </div>
//       <div className="h-[70%] w-full flex flex-col overflow-y-scroll border-2">
//         <table className="table-auto px-2 max-h-full">
//           <thead className="text-left px-3 w-full sticky top-0 bg-blue-950 text-white">
//             <tr className="px-3">
//               <th className="pl-2">#</th>
//               <th>TIME</th>
//               <th>TYPE</th>
//               <th>URL</th>
//               <th className="text-center">ACTIONS</th>
//             </tr>
//           </thead>
//           <tbody className="text-left">
//             {scheduleState.items.map((schedule, index) => {
//               return (
//                 <tr className="" key={index}>
//                   <td className="p-2">{index + 1}</td>
//                   <td>{schedule.time}</td>
//                   <td>{schedule.type}</td>
//                   <td>{schedule.file}</td>
//                   <td>
//                     <div className="flex justify-evenly">
//                       <FaTrash className="text-red-600 cursor-pointer text-xl hover:border-2" />
//                       <FaEdit className="text-yellow-600 cursor-pointer text-xl hover:border-2" />
//                     </div>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//       <div className="w-full h-[10%] flex justify-center mt-2">
//         <button
//           className="p-2 bg-green-900 text-white w-36 ml-5"
//           onClick={onSaveScheduleClick}
//         >
//           Save
//         </button>
//       </div>
//     </div>
//   </div>
// </>; */
// }
