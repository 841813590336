import { createSlice } from "@reduxjs/toolkit";
import { FileInfo } from "../../../domain/entities/FileInfo";
import { dummyVideoList } from "../../../data/dummy/dummyVideoList";
import { CloudinaryDatasource } from "../../../data/firebase/cloudinaryDatasource";
import { FirebaseVideoDataSource } from "../../../data/firebase/videoDatasource";

type TVideoState = {
  videoList: FileInfo[] | [];
  currentVideo: FileInfo | null;
  uploading: boolean;
};

const initialVideoState: TVideoState = {
  videoList: [],
  currentVideo: null,
  uploading: false,
};

export const videoSlice = createSlice({
  name: "Video",
  initialState: initialVideoState,
  reducers: {
    setVideoList: (state, action) => {
      state.videoList = action.payload;
    },
    setCurrentVideo: (state, action) => {
      state.videoList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      CloudinaryDatasource.uploadVideo.fulfilled,
      (state, action) => {
        const fileInfo: any = action.payload;
        console.log(fileInfo);

        FirebaseVideoDataSource.writeVideoFileInfo(fileInfo);
        FirebaseVideoDataSource.getVideos();
        state.uploading = false;
        console.log("upload video successfully", fileInfo);
      }
    );
    builder.addCase(
      CloudinaryDatasource.uploadVideo.pending,
      (state, action) => {
        state.uploading = true;
      }
    );
  },
});

export const { setVideoList, setCurrentVideo } = videoSlice.actions;
