import { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import FileListBox from "../components/FileListBox";
import { FirebaseImageDataSource } from "../../data/firebase/imageDataSource";
import { CloudinaryDatasource } from "../../data/firebase/cloudinaryDatasource";
import { FileInfo } from "../../domain/entities/FileInfo";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";
import { Constants } from "../../constants";
import { FirebaseVideoDataSource } from "../../data/firebase/videoDatasource";
import ReactPlayer from "react-player";

const AdminVideoPage = () => {
  const uploadImg = require("../../assets/images/upload.png");
  const inputFileRef = useRef<HTMLInputElement>(null);
  const videoState = useAppSelector((state) => state.videoReducer);
  const dispatch = useAppDispatch();
  const [video, setVideo] = useState<FileInfo>();

  const getVideoList = async () => {
    await FirebaseVideoDataSource.getVideos();
  };

  const getCurrentVideo = async () => {
    const f: any = await FirebaseVideoDataSource.getCurrentVideo();
    setVideo(f);
  };

  useEffect(() => {
    getVideoList();
    getCurrentVideo();
  }, []);

  const onInputFileChange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    const fileExist = videoState.videoList.filter(
      (video) => video.filename === file.name
    )[0];
    if (fileExist)
      return alert(
        "Filename already exist, choose another name or delete existing one"
      );
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "ImageCloudinaru");
    data.append("cloud_name", Constants.cloudinaryCloudName);
    data.append("folder", Constants.cloudinaryVideoFolder);
    dispatch(CloudinaryDatasource.uploadVideo(data));
  };

  const onDeleteFileClick = (file: FileInfo) => {
    // console.log(id);
    FirebaseVideoDataSource.deleteVideo(file.id);
    FirebaseVideoDataSource.getVideos();
  };

  const onShareFileClick = (file: FileInfo) => {
    // const currentVideoRef = doc(db, "currentVideo", "Video");
    // updateDoc(currentVideoRef, {
    //   filename: file.filename,
    //   publicId: file.publicId,
    //   url: file.url,
    // });
    // setVideo(file);
    const currentFileRef = doc(db, "currentFile", "File");
    updateDoc(currentFileRef, {
      type: "Video",
      url: file.url,
    });
    setVideo(file);
  };

  return (
    <div className="h-screen w-screen flex flex-col">
      {videoState.uploading && (
        <div className="h-[90%] w-full flex justify-center items-center">
          Upload Video...
        </div>
      )}
      {!videoState.uploading && (
        <div className="h-[90%] w-full flex">
          <div className="w-[30%] p-2 flex flex-col gap-2">
            <div className="w-full h-[20%] flex gap-3 justify-center items-center border-2">
              <img
                src={uploadImg}
                alt=""
                className="h-[50%] cursor-pointer"
                onClick={() => {
                  inputFileRef.current!.click();
                }}
              />
              <button
                onClick={() => {
                  inputFileRef.current!.click();
                }}
              >
                Choose File
              </button>
              <input
                type="file"
                className="hidden"
                accept=".mp4"
                onChange={onInputFileChange}
                ref={inputFileRef}
              />
            </div>
            <div className="w-full h-[78%] border-2">
              <FileListBox
                files={videoState.videoList}
                onDeleteClick={onDeleteFileClick}
                onShareClick={onShareFileClick}
              />
            </div>
          </div>
          <div className="w-[70%] p-2">
            <div className="w-full h-full border-2 flex flex-col">
              <div className="w-full flex p-2 bg-slate-700 text-white">
                {video?.filename}
              </div>

              <div className="w-full h-full flex p-2">
                <ReactPlayer
                  width="100%"
                  height="90%"
                  url={video?.url}
                  loop
                  playing={true}
                  controls
                  muted
                />
                {/* <video
                  src={video?.url}
                  autoPlay
                  muted
                  loop
                  controls
                  className="h-full"
                ></video> */}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="h-[10%]">
        <Navbar />
      </div>
    </div>
  );
};

export default AdminVideoPage;
