import { collection, doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../connection/firebaseConfig";
// import { socket } from "../../connection/socketManager";

const NavbarRunSchedule = () => {
  const navigate = useNavigate();
  const pageRef = doc(db, "app", "VbCQEyzjAZKTeLTCyN3r");
  const btnClass =
    "bg-red-500 w-32 h-10 text-white cursor-pointer hover:bg-red-600";

  const onBtnStopClick = () => {
    navigate("/adminCreateSchedule");
    updateDoc(pageRef, { page: "/adminCreateSchedule" });
  };

  return (
    <div className="h-full flex gap-4 justify-center items-center bg-slate-400">
      <button className={btnClass} onClick={onBtnStopClick}>
        Stop
      </button>
    </div>
  );
};

export default NavbarRunSchedule;
