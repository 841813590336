import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";
import { store } from "../../presentation/redux/store";

import { Schedule } from "../../domain/entities/Schedule";
import {
  setCurrentSchedule,
  setScheduleList,
} from "../../presentation/redux/slices/scheduleSlice";
// import { setScheduleList } from "../../presentation/redux/slices/appSlice";

export class FirebaseScheduleDataSource {
  static getSchedules = async () => {
    const schedulesCollectionRef = collection(db, "schedules");
    const querySnapshot = await getDocs(schedulesCollectionRef);
    const scheduleList: Schedule[] = [];
    querySnapshot.forEach((doc: any) => {
      const schedule = doc.data();
      schedule.id = doc.id;
      scheduleList.push(schedule);
    });
    // console.log(scheduleList);
    store.dispatch(setScheduleList(scheduleList));
  };

  static deleteSchedule = async (id: string) => {
    await deleteDoc(doc(db, "schedules", id));
  };

  static createSchedule = async (schedule: Schedule) => {
    await addDoc(collection(db, "schedules"), {
      name: schedule.name,
      items: schedule.items,
    });
  };

  static getCurrentSchedule = async () => {
    // const currentScheduleRef = doc(db, "currentSchedule", "Schedule");
    // const snap = await getDoc(currentScheduleRef);
    // const currentSchedule = snap.data();
    // console.log(currentSchedule);
    // return currentSchedule;
    // return (await getDoc(doc(db, "currentSchedule", "Schedule"))).data();
    const snap = (await getDoc(doc(db, "currentSchedule", "Schedule"))).data();
    // console.log(snap);

    const id = snap ? snap.id : "";
    // console.log(id);

    const currentSchedule = await FirebaseScheduleDataSource.getScheduleById(
      id
    );
    // console.log(currentSchedule);
    return currentSchedule;

    // store.dispatch(setCurrentSchedule(currentSchedule));
  };

  static getScheduleById = async (id: string) => {
    return (await getDoc(doc(db, "schedules", id))).data();
  };
}
