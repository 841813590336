import { createSlice } from "@reduxjs/toolkit";
import { FileInfo } from "../../../domain/entities/FileInfo";
import { CloudinaryDatasource } from "../../../data/firebase/cloudinaryDatasource";
import { FirebaseImageDataSource } from "../../../data/firebase/imageDataSource";
// import { dummyImageList } from "../../../data/dummy/dummyImageList";

type TImageState = {
  imageList: FileInfo[] | [];
  currentImage: FileInfo | null;
  uploading: boolean;
};

const initialImageState: TImageState = {
  imageList: [],
  currentImage: null,
  uploading: false,
};

export const imageSlice = createSlice({
  name: "Image",
  initialState: initialImageState,
  reducers: {
    setImageList: (state, action) => {
      state.imageList = action.payload;
    },
    setCurrentImage: (state, action) => {
      state.currentImage = action.payload;
      // console.log(state.currentImage);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      CloudinaryDatasource.uploadImage.fulfilled,
      (state, action) => {
        const fileInfo: any = action.payload;
        FirebaseImageDataSource.writeImageFileInfo(fileInfo);
        FirebaseImageDataSource.getImages();
        state.uploading = false;
        console.log("upload image successfully", fileInfo);
      }
    );
    builder.addCase(
      CloudinaryDatasource.uploadImage.pending,
      (state, action) => {
        state.uploading = true;
      }
    );
  },
});

export const { setImageList, setCurrentImage } = imageSlice.actions;
