import React from "react";
import { useAppSelector } from "../redux/hooks";
import { FaShare, FaTrash } from "react-icons/fa";
import { FirebaseScheduleDataSource } from "../../data/firebase/scheduleDatasource";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";

const ScheduleList = (props: { classname: string }) => {
  const scheduleState = useAppSelector((state) => state.scheduleReducer);
  const navigate = useNavigate();
  const pageRef = doc(db, "app", "VbCQEyzjAZKTeLTCyN3r");
  const currentScheduleRef = doc(db, "currentSchedule", "Schedule");

  const onDeleteScheduleClick = (id: string) => {
    // console.log(id);
    FirebaseScheduleDataSource.deleteSchedule(id);
    FirebaseScheduleDataSource.getSchedules();
  };

  const onShareScheduleClick = (id: string) => {
    // console.log(id);
    updateDoc(currentScheduleRef, { id });
    updateDoc(pageRef, { page: "/adminRunSchedule" });
    navigate("/adminRunSchedule");
  };

  return (
    <div className={props.classname}>
      {scheduleState.scheduleList.map((schedule, index) => {
        return (
          <div key={index} className="w-full border-2 p-1 flex items-center">
            <div className="w-[80%]">{schedule.name}</div>
            <div className="w-[20%] flex gap-4">
              <FaTrash
                className="cursor-pointer text-red-600"
                onClick={() => onDeleteScheduleClick(schedule.id)}
              />
              <FaShare
                className="cursor-pointer text-green-600"
                onClick={() => onShareScheduleClick(schedule.id)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ScheduleList;
