import Navbar from "../components/Navbar";
import CreateScheduleContainer from "../components/CreateScheduleContainer";
import { useEffect } from "react";
import { FirebaseAppDataSource } from "../../data/firebase/appDatasource";
import { FirebaseScheduleDataSource } from "../../data/firebase/scheduleDatasource";

const AdminCreateSchedulePage = () => {
  useEffect(() => {
    FirebaseScheduleDataSource.getSchedules();
  }, []);
  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="h-[90%] p-2 w-full flex justify-center items-center">
        <CreateScheduleContainer />
      </div>
      <div className="h-[10%]">
        <Navbar />
      </div>
    </div>
  );
};

export default AdminCreateSchedulePage;
