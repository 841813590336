import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../connection/firebaseConfig";
import { store } from "../../presentation/redux/store";
import { setIsAuth, setUser } from "../../presentation/redux/slices/authSlice";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export class FirebaseAuthDatasource {
  static signIn = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        cookies.set("auth-token", userCredential.user.refreshToken);
        store.dispatch(setIsAuth(true));
        store.dispatch(
          setUser({
            email: userCredential.user.email,
            uid: userCredential.user.uid,
          })
        );
      })
      .catch((err) => {
        return alert("Login Failed" + err);
      });
  };

  //   static signin = createAsyncThunk(
  //     "firebase_signin",
  //     async ({ email, password }: { email: string; password: string }) => {
  //       console.log(email, password);

  //       return signInWithEmailAndPassword(auth, email, password)
  //         .then((userCredential) => {
  //           console.log("joss", userCredential);
  //           cookies.set("auth-token", userCredential.user.refreshToken);
  //           store.dispatch(
  //             setUser({
  //               email: userCredential.user.email,
  //               uid: userCredential.user.uid,
  //             })
  //           );
  //         })
  //         .catch((err) => {
  //           return alert("Login Failed" + err);
  //         });
  //     }
  //   );

  //   static signout = createAsyncThunk("firebase_signout", async () => {
  //     return signOut(auth).then().catch();
  //   });
}
