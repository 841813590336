import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { FileInfo } from "../../domain/entities/FileInfo";
import { FirebaseVideoDataSource } from "../../data/firebase/videoDatasource";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";
import ReactPlayer from "react-player";

const ClientVideoContainer = () => {
  // const videoState = useAppSelector((state) => state.videoReducer);
  // const dispatch = useAppDispatch()

  const [video, setVideo] = useState<FileInfo>();

  const getCurrentVideo = async () => {
    const f: any = await FirebaseVideoDataSource.getCurrentVideo();
    setVideo(f);
  };

  useEffect(() => {
    getCurrentVideo();
    // const queryApp = collection(db, "currentVideo");
    // onSnapshot(queryApp, (snapshot) => {
    //   snapshot.docs.map((document) => {
    //     setVideo(document.data().url);
    //   });
    // });
    const queryApp = collection(db, "currentFile");
    onSnapshot(queryApp, (snapshot) => {
      snapshot.docs.map((document: any) => {
        // setVideo(document.data());
        const data = document.data();
        if (data.type === "Video") setVideo(data);
      });
    });
  }, []);

  return (
    <div className="w-full h-full flex bg-red-500 p-2">
      <ReactPlayer
        width="100%"
        height="100%"
        url={video?.url}
        loop
        playing={true}
        controls
        muted
      />
      {/* <video
        src={video?.url}
        autoPlay
        muted
        loop
        className="w-screen h-screen"
      ></video> */}
    </div>
  );
};

export default ClientVideoContainer;
