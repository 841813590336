import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../../domain/entities/User";

type Time = {
  h: number;
  m: number;
  s: number;
};

type AppState = {
  user: User | null;
  indexPage: number;
  now: Time;
};

const initialAppSlice: AppState = {
  user: null,
  indexPage: 0,
  now: { h: 0, m: 0, s: 0 },
};

export const appSlice = createSlice({
  name: "App",
  initialState: initialAppSlice,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIndexPage: (state, action) => {
      state.indexPage = action.payload;
    },
    setTime: (state, action) => {
      state.now = action.payload;
    },
    // setScheduleList: (state, action) => {
    //   state.scheduleList = action.payload;
    // },
  },
  extraReducers: (builder) => {},
});

export const { setIndexPage, setTime } = appSlice.actions;
