// import React, { useEffect } from "react";
// import { FirebaseScheduleDataSource } from "../../data/firebase/scheduleDatasource";
// import { setCurrentSchedule } from "../redux/slices/scheduleSlice";
// import { useAppDispatch, useAppSelector } from "../redux/hooks";
// import ReactPlayer from "react-player";

import ReactPlayer from "react-player";

const RunScheduleContainer = (props: {
  file: { type: string; url: string };
}) => {
  // const scheduleState = useAppSelector((state) => state.scheduleReducer);
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   console.log(1);
  // }, [props.index]);
  console.log(props.file.url);

  return (
    <div className="w-full h-full border-2">
      {props.file.type === "Video" && (
        // <video src={props.file.url} autoPlay muted loop></video>
        <ReactPlayer
          width="100%"
          height="100%"
          url={props.file.url}
          loop
          playing={true}
          controls
          muted
        />
      )}
      {props.file.type === "Image" && (
        <div
          className="w-full h-full flex p-2"
          style={{
            backgroundImage: `url(${props.file.url})`,
            backgroundSize: "100% 100%",
          }}
        ></div>
      )}
    </div>
  );
};

export default RunScheduleContainer;

//   const getCurrentSchedule = async () => {
//     const x = await FirebaseScheduleDataSource.getCurrentSchedule();
//     console.log(x);
//     dispatch(setCurrentSchedule(x));
//     return x;
//   };

//   useEffect(() => {
//     getCurrentSchedule();
//     console.log(1);

//     //     // const currentSchedule = getCurrentSchedule();
//     //     // console.log(scheduleState.currentSchedule);

//     //     const timer = setInterval(() => {
//     //       let time = new Date();
//     //       const h = time.getHours();
//     //       const m = time.getMinutes();
//     //       const s = time.getSeconds();
//     //       // let strTime = `${h < 10 ? "0" : ""}${h} : ${m < 10 ? "0" : ""}${m} : ${
//     //       //   s < 10 ? "0" : ""
//     //       // }${s}`;
//     //       //   setStrTime(
//     //       //     `${h < 10 ? "0" : ""}${h} : ${m < 10 ? "0" : ""}${m} : ${
//     //       //       s < 10 ? "0" : ""
//     //       //     }${s}`
//     //       //   );
//     //       console.log(scheduleState.currentSchedule);

//     //       // for (let i = 0; i < scheduleState.schedule.items.length; i++) {
//     //       //   const t = scheduleState.schedule.items[i].Time?.split(":").map((ts) =>
//     //       //     parseInt(ts)
//     //       //   );
//     //       //   console.log(t);
//     //       //   if (h === t![0] && m === t![1] && s === 0) {
//     //       //     console.log(scheduleState.schedule.items[i]);
//     //       //     setIndex(i);
//     //       //     break;
//     //       //   }
//     //       // }
//     //       return () => clearInterval(timer);
//     //     }, 1000);
//   }, []);
