import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ScheduleItem } from "../../domain/entities/ScheduleItem";
import { setSchedule } from "../redux/slices/scheduleSlice";

const ScheduleItemList = (props: { classname: string }) => {
  const scheduleState = useAppSelector((state) => state.scheduleReducer);
  const dispatch = useAppDispatch();

  const onDeleteScheduleItem = (scheduleItem: ScheduleItem) => {
    const items = scheduleState.schedule.items.filter(
      (item) => item.time !== scheduleItem.time
    );
    dispatch(setSchedule({ ...scheduleState.schedule, items }));
  };

  return (
    <div className={props.classname}>
      <table className="table-auto px-2 max-h-full w-full">
        <thead className="text-left w-full sticky top-0 bg-blue-950 text-white">
          <tr className="px-3">
            <th className="pl-2">#</th>
            <th>TIME</th>
            <th>TYPE</th>
            <th>URL</th>
            <th className="text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="text-left w-full border-2">
          {scheduleState.schedule.items.map((item, index) => {
            return (
              <tr key={index}>
                <td className="border-2 p-1">{index + 1}</td>
                <td className="border-2 p-1">{item.time}</td>
                <td className="border-2 p-1">{item.type}</td>
                <td className="border-2 p-1">{item.file}</td>
                <td className="border-2 p-1">
                  <div className="flex justify-evenly">
                    <FaTrash
                      className="text-red-600 cursor-pointer text-xl hover:border-2"
                      onClick={() => onDeleteScheduleItem(item)}
                    />
                    <FaEdit className="text-yellow-600 cursor-pointer text-xl hover:border-2" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleItemList;
