import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";
import { FileInfo } from "../../domain/entities/FileInfo";
import { store } from "../../presentation/redux/store";
import { setImageList } from "../../presentation/redux/slices/imageSlice";

export class FirebaseImageDataSource {
  static getImages = async () => {
    const imagesCollectionRef = collection(db, "images");
    const querySnapshot = await getDocs(imagesCollectionRef);
    const imageList: FileInfo[] = [];
    querySnapshot.forEach((doc: any) => {
      const { filename, url, publicId } = doc.data();
      const image: FileInfo = { id: doc.id, filename, url, publicId };
      imageList.push(image);
    });
    store.dispatch(setImageList(imageList));
  };

  static deleteImage = async (id: string) => {
    await deleteDoc(doc(db, "images", id));
  };

  static writeImageFileInfo = async (fileInfo: FileInfo) => {
    const imagesCollectionRef = collection(db, "images");
    await addDoc(imagesCollectionRef, fileInfo);
  };

  static writeImages = async (imageList: FileInfo[]) => {
    const imagesCollectionRef = collection(db, "images");
    // const imageListDocRef =  doc(db, "images", "MosgJNkNQduyIY0JlR6D");
    imageList.forEach(async (image) => {
      await addDoc(imagesCollectionRef, image);
    });
  };

  static getCurrentImage = async () => {
    // const imagesCollectionRef = collection(db, "currentImage");
    const currentImageRef = doc(db, "currentImage", "Image");
    const snap = await getDoc(currentImageRef);
    return snap.data();
    // console.log(snap.data());
  };

  static getImageById = async (id: string) => {
    const imageRef = doc(db, "images", id);
    const snap = await getDoc(imageRef);
    return snap.data();
  };
}

// const currentImageRef = doc(db, "currentImage", "Image");
// updateDoc(currentImageRef, {
//   filename: file.filename,
//   publicId: file.publicId,
//   url: file.url,
// });
