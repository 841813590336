import { useEffect, useState } from "react";
import NavbarRunSchedule from "../components/NavbarRunSchedule";
import RunScheduleContainer from "../components/RunScheduleContainer";
// import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { FirebaseScheduleDataSource } from "../../data/firebase/scheduleDatasource";
import { Schedule } from "../../domain/entities/Schedule";
import { FirebaseImageDataSource } from "../../data/firebase/imageDataSource";
import { FirebaseVideoDataSource } from "../../data/firebase/videoDatasource";
import { FirebaseAppDataSource } from "../../data/firebase/appDatasource";
import { FilePlaying } from "../../domain/FIlePlaying";
// import { useAppDispatch, useAppSelector } from "../redux/hooks";
// import { setRunFile } from "../redux/slices/scheduleSlice";
// import { Core } from "../../core";
// import { Schedule } from "../../domain/entities/Schedule";
// import { setCurrentSchedule } from "../redux/slices/scheduleSlice";
// import { setCurrentSchedule } from "../redux/slices/scheduleSlice";

const AdminRunSchedulePage = () => {
  const [strTime, setStrTime] = useState("");
  const [file, setFile] = useState<FilePlaying>({ type: "Image", url: "" });

  let timer: NodeJS.Timer;

  // const getCurrentSchedule = async () => {
  //   const currentSchedule =
  //     (await FirebaseScheduleDataSource.getCurrentSchedule()) as Schedule;

  //   const getTimeInMinuteNow = () => {
  //     let time = new Date();
  //     const h = time.getHours();
  //     const m = time.getMinutes();
  //     // const s = time.getSeconds();
  //     return h * 24 + m;
  //   };

  //   const getTimeInMinuteFromStrTime = (time: string) => {
  //     const t = time.split(":").map((ts) => parseInt(ts));
  //     return t[1] * 24 + t[0];
  //   };

  //   const getFile = async (i: number) => {
  //     const type = currentSchedule.items[i].type;
  //     const f =
  //       type === "Image"
  //         ? await FirebaseImageDataSource.getImageById(
  //             currentSchedule.items[i].file
  //           )
  //         : await FirebaseVideoDataSource.getVideoById(
  //             currentSchedule.items[i].file
  //           );
  //     return { type, url: f?.url };
  //   };

  //   let i = 0;
  //   for (i = 0; i < currentSchedule.items.length - 1; i++) {
  //     const intTimeNow = getTimeInMinuteNow();
  //     const t1 = getTimeInMinuteFromStrTime(currentSchedule.items[i].time);
  //     const t2 = getTimeInMinuteFromStrTime(currentSchedule.items[i + 1].time);

  //     if (t1 <= intTimeNow && intTimeNow < t2) {
  //       setFile(await getFile(i));
  //       break;
  //     }
  //   }

  //   console.log(i);
  //   if (i === currentSchedule.items.length - 1) {
  //     setFile(await getFile(0));
  //   }

  //   timer = setInterval(async () => {
  //     let time = new Date();
  //     const h = time.getHours();
  //     const m = time.getMinutes();
  //     const s = time.getSeconds();

  //     for (let i = 0; i < currentSchedule.items.length; i++) {
  //       const t = currentSchedule.items[i].time
  //         .split(":")
  //         .map((ts) => parseInt(ts));

  //       if (h === t[0] && m === t[1] && s === 0) {
  //         setFile(await getFile(i));
  //         break;
  //       }
  //     }
  //   }, 1000);

  //   // timer = setInterval(async () => {
  //   //   let time = new Date();
  //   //   const h = time.getHours();
  //   //   const m = time.getMinutes();
  //   //   const s = time.getSeconds();

  //   //   for (let i = 0; i < currentSchedule.items.length; i++) {
  //   //     const t = currentSchedule.items[i].time
  //   //       .split(":")
  //   //       .map((ts) => parseInt(ts));

  //   //     if (h === t[0] && m === t[1] && s === 0) {
  //   //       const type = currentSchedule.items[i].type;
  //   //       const f =
  //   //         type === "Image"
  //   //           ? await FirebaseImageDataSource.getImageById(
  //   //               currentSchedule.items[i].file
  //   //             )
  //   //           : await FirebaseVideoDataSource.getVideoById(
  //   //               currentSchedule.items[i].file
  //   //             );

  //   //       setFile({ type, url: f?.url });
  //   //       // console.log(image);
  //   //       // dispatch(setRunFile({ type, url: f?.url }));
  //   //       break;
  //   //     }
  //   //   }
  //   // }, 1000);
  // };

  const runSchedule = async () => {
    const currentFile =
      (await FirebaseAppDataSource.getCurrentFile()) as FilePlaying;
    setFile(currentFile);
    const currentSchedule =
      (await FirebaseScheduleDataSource.getCurrentSchedule()) as Schedule;
    timer = setInterval(async () => {
      let time = new Date();
      const h = time.getHours();
      const m = time.getMinutes();
      const s = time.getSeconds();
      console.log(1);

      for (let i = 0; i < currentSchedule.items.length; i++) {
        const t = currentSchedule.items[i].time
          .split(":")
          .map((ts) => parseInt(ts));

        if (h === t[0] && m === t[1] && s === 0) {
          const type = currentSchedule.items[i].type;
          const f =
            type === "Image"
              ? await FirebaseImageDataSource.getImageById(
                  currentSchedule.items[i].file
                )
              : await FirebaseVideoDataSource.getVideoById(
                  currentSchedule.items[i].file
                );
          await FirebaseAppDataSource.setCurrentFile({ type, url: f?.url });
          setFile({ type, url: f?.url });
          // console.log(image);
          // dispatch(setRunFile({ type, url: f?.url }));
          break;
        }
      }
    }, 1000);
  };

  useEffect(() => {
    runSchedule();
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="h-[90%] p-2 flex flex-col gap-2">
        <div className="w-full flex border-2">{strTime}</div>
        <RunScheduleContainer file={file} />
      </div>
      <div className="h-[10%]">
        <NavbarRunSchedule />
      </div>
    </div>
  );
};

export default AdminRunSchedulePage;

// console.log(1);
// setStrTime(
//   `${h < 10 ? "0" : ""}${h} : ${m < 10 ? "0" : ""}${m} : ${
//     s < 10 ? "0" : ""
//   }${s}`
// );
