import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { FileInfo } from "../../domain/entities/FileInfo";
import { TimeUtils } from "../../utils/timeUtils";
import { ScheduleItem } from "../../domain/entities/ScheduleItem";
import { insertScheduleItem } from "../redux/slices/scheduleSlice";

const ScheduleItemForm = (props: { classname: string }) => {
  const imageState = useAppSelector((state) => state.imageReducer);
  const videoState = useAppSelector((state) => state.videoReducer);
  const scheduleState = useAppSelector((state) => state.scheduleReducer);
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<FileInfo[]>([]);
  const [type, setType] = useState("Image");
  const [time, setTime] = useState<string>(TimeUtils.DateToTimeHHMM());
  const [fileSelected, setFileSelected] = useState<FileInfo>();

  useEffect(() => {
    setFiles(imageState.imageList);
  }, [imageState]);

  const onSelectType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "Image") {
      setFiles(imageState.imageList);
    } else {
      setFiles(videoState.videoList);
    }
    setType(e.target.value);
  };

  const onInputTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const onFileSelected = (e: any, selected: FileInfo) => {
    console.log(e.target, selected.id);
    files.map((file) => {
      document.getElementById(file.id)!.style.backgroundColor =
        file.id === selected.id ? "blue" : "white";
      document.getElementById(file.id)!.style.color =
        file.id === selected.id ? "white" : "black";
    });
    setFileSelected(selected);
  };

  const onBtnAddScheduleItemClick = () => {
    if (time === "") return alert("Fill Time please!");

    const timeExist =
      scheduleState.schedule.items.filter((item) => {
        return item.time === time;
      }).length > 0;
    if (timeExist)
      return alert(
        "Time already exist, choose another time, or delete existing time"
      );

    if (!fileSelected) return alert("choose at least one file please!");

    const scheduleItem: ScheduleItem = {
      time,
      type,
      file: fileSelected!.id,
    };
    console.log(scheduleItem);
    dispatch(insertScheduleItem(scheduleItem));
  };

  return (
    <div className={props.classname}>
      <div className="flex items-center">
        <span className="w-24">Time</span>
        <input
          type="time"
          className="p-1 border-2 w-full"
          value={time}
          onChange={onInputTimeChange}
        />
      </div>
      <div className="flex items-center">
        <span className="w-24">Type</span>
        <select className="p-1 border-2 w-full" onChange={onSelectType}>
          <option value="Image">Image</option>
          <option value="Video">Video</option>
        </select>
      </div>
      <div className="flex flex-col gap-1 border-2 p-1 h-full">
        {files.map((file, index) => {
          return (
            <div
              key={file.id}
              id={file.id}
              className="cursor-pointer"
              onClick={(e) => onFileSelected(e, file)}
            >
              {file.filename}
            </div>
          );
        })}
      </div>
      <button
        className="p-2 bg-blue-900 text-white w-full"
        onClick={onBtnAddScheduleItemClick}
      >
        Add Schedule
      </button>
    </div>
  );
};

export default ScheduleItemForm;
