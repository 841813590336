import { useRef } from "react";
import { FirebaseAuthDatasource } from "../../data/firebase/authDatasource";
import Cookies from "universal-cookie";
import { useAppDispatch } from "../redux/hooks";
const cookies = new Cookies();

const LoginContainer = () => {
  const dispatch = useAppDispatch();
  const inputEmailRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);

  const onBtnLoginClick = () => {
    const email = inputEmailRef.current!.value;
    const password = inputPasswordRef.current!.value;
    FirebaseAuthDatasource.signIn({ email, password });
    // dispatch(FirebaseAuthDatasource.signin({ email, password }));
    // console.log(cookies.get("auth-token"));
  };

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="w-96 border-2 p-2">
        <div className="flex mb-4 items-center">
          <span className="w-24">Email</span>
          <input
            type="email"
            className="border-2 p-2 w-full"
            ref={inputEmailRef}
          />
        </div>
        <div className="flex mb-8 items-center">
          <span className="w-24">Password</span>
          <input
            type="text"
            className="border-2 p-2 w-full"
            ref={inputPasswordRef}
          />
        </div>
        <div className="flex">
          <button
            className="bg-blue-800 text-white p-2 w-full rounded-xl"
            onClick={onBtnLoginClick}
          >
            Login
          </button>
          {/* <button
            onClick={() => {
              console.log(cookies.get("auth-token"));
            }}
          >
            click
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
