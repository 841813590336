import Navbar from "../components/Navbar";
import LoginContainer from "../components/LoginContainer";
import { useAppSelector } from "../redux/hooks";

const AdminHomePage = () => {
  const authState = useAppSelector((state) => state.authReducer);

  if (!authState.isAuth) {
    return <LoginContainer />;
  }

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="h-[90%] flex justify-center items-center">Welcome {}</div>
      <div className="h-[10%]">
        <Navbar />
      </div>
    </div>
  );
};

export default AdminHomePage;
