import { useAppDispatch, useAppSelector } from "../redux/hooks";
import ClientVideoContainer from "../components/ClientVideoContainer";
import ClientImageContainer from "../components/ClientImageContainer";
import ClientNoteContainer from "../components/ClientNoteContainer";
import ClientRunScheduleContainer from "../components/ClientRunScheduleContainer";
import ClientHomeContainer from "../components/ClientHomeContainer";
import { useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";
import { Constants } from "../../constants";
import { setIndexPage } from "../redux/slices/appSlice";
import { FirebaseAppDataSource } from "../../data/firebase/appDatasource";

const ClientPage = () => {
  const appState = useAppSelector((state) => state.appReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // FirebaseAppDataSource.getCurrentImage();
    const queryApp = collection(db, "app");
    onSnapshot(queryApp, (snapshot) => {
      // const temp = appState.indexPage;
      // console.log(temp);

      snapshot.docs.map((document) => {
        const page = document.data().page;
        let indexPage = Constants.pages.indexOf(page);
        if (indexPage === 5) indexPage = 0;
        dispatch(setIndexPage(indexPage));
        // console.log(page, indexPage);
        // if (indexPage != 5) dispatch(setIndexPage(indexPage));
      });
    });
  }, []);

  return (
    <div className="w-screen h-screen">
      {appState.indexPage === 0 && <ClientHomeContainer />}
      {appState.indexPage === 1 && <ClientVideoContainer />}
      {appState.indexPage === 2 && <ClientImageContainer />}
      {appState.indexPage === 3 && <ClientNoteContainer />}
      {appState.indexPage === 4 && <ClientRunScheduleContainer />}
      {/* {appState.indexPage === 5 && <ClientHomeContainer />} */}
    </div>
  );
};

export default ClientPage;
