// import { auth, provider } from "./connection/firebaseConfig";
import { Route, Routes } from "react-router-dom";

import AdminVideoPage from "./presentation/pages/AdminVideoPage";
import AdminImagePage from "./presentation/pages/AdminImagePage";
import AdminNotePage from "./presentation/pages/AdminNotePage";
import AdminCreateSchedulePage from "./presentation/pages/AdminCreateSchedulePage";
import AdminRunSchedulePage from "./presentation/pages/AdminRunSchedulePage";
import ClientPage from "./presentation/pages/ClientPage";
import AdminHomePage from "./presentation/pages/AdminHomePage";
import { useEffect } from "react";
import { FirebaseImageDataSource } from "./data/firebase/imageDataSource";
import { FirebaseAppDataSource } from "./data/firebase/appDatasource";
import { FirebaseVideoDataSource } from "./data/firebase/videoDatasource";
import { FirebaseScheduleDataSource } from "./data/firebase/scheduleDatasource";
// import { collection, onSnapshot } from "firebase/firestore";
// import { db } from "./connection/firebaseConfig";

const App = () => {
  useEffect(() => {
    FirebaseImageDataSource.getImages();
    FirebaseVideoDataSource.getVideos();
    FirebaseScheduleDataSource.getSchedules();
    // FirebaseScheduleDataSource.getCurrentSchedule();
    // FirebaseAppDataSource.getCurrentImage();
    // const queryApp = collection(db, "app");
    // const snapshot = onSnapshot(queryApp, (snapshot) => {
    //   snapshot.docs.map((document) => {
    //     // const currentImage = document.data().currentImage;
    //     console.log(document.data().currentImage);
    //   });
    //   // return () => delete snapshot
    // });
    // FirebaseImageDataSource.writeImages(dummyFileInfos);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AdminHomePage />} />
      <Route path="/adminHome" element={<AdminHomePage />} />
      <Route path="/adminVideo" element={<AdminVideoPage />} />
      <Route path="/adminImage" element={<AdminImagePage />} />
      <Route path="/adminNote" element={<AdminNotePage />} />
      <Route
        path="/adminCreateSchedule"
        element={<AdminCreateSchedulePage />}
      />
      <Route path="/adminRunSchedule" element={<AdminRunSchedulePage />} />
      <Route path="/client" element={<ClientPage />} />
    </Routes>
  );
};

export default App;

// export const AuthTest = () => {
//   // const [user, setUser] = useState<User>({ email: "", uid: "" });
//   const authState = useAppSelector((state) => state.authReducer);
//   const signInWithGoogle = async () => {
//     await signInWithPopup(auth, provider);
//   };
//   const dispatch = useAppDispatch();

//   return (
//     <>
//       <div className="flex gap-5">
//         <span>{authState.user?.email}</span>
//         <button
//           className="bg-red-300"
//           onClick={() => {
//             console.log("signin");

//             dispatch(
//               FirebaseAuthDatasource.signin({
//                 email: "admindashboard@abc.com",
//                 password: "mydreamcomestrue.123#",
//               })
//             );
//           }}
//           // onClick={() => {
//           // signInWithEmailAndPassword(
//           //   auth,
//           //   "admindashboard@abc.com",
//           //   "mydreamcomestrue.123#"
//           // ).then((userCredential) => {
//           //   console.log(userCredential);
//           //   setUser({
//           //     email: userCredential.user.email,
//           //     uid: userCredential.user.uid,
//           //   });
//           // });
//           // }}
//         >
//           SignIn
//         </button>
//         <button
//           className="bg-red-300"
//           onClick={() => {
//             // console.log(user);
//           }}
//         >
//           user
//         </button>
//         <button
//           className="bg-red-300"
//           onClick={() => dispatch(FirebaseAuthDatasource.signout())}
//           // onClick={async () => {
//           //   await signOut(auth);
//           //   console.log(user);
//           // }}
//         >
//           Logout
//         </button>
//       </div>
//     </>
//   );
// };
