import { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import FileListBox from "../components/FileListBox";
import { FirebaseImageDataSource } from "../../data/firebase/imageDataSource";
import { CloudinaryDatasource } from "../../data/firebase/cloudinaryDatasource";
import { FileInfo } from "../../domain/entities/FileInfo";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";

const AdminImagePage = () => {
  const uploadImg = require("../../assets/images/upload.png");
  const inputFileRef = useRef<HTMLInputElement>(null);
  const imageState = useAppSelector((state) => state.imageReducer);
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<FileInfo>();

  const getCurrentImage = async () => {
    const f: any = await FirebaseImageDataSource.getCurrentImage();
    setImage(f);
  };

  useEffect(() => {
    getCurrentImage();
  }, []);

  const onInputFileChange = async (e: any) => {
    const file = e.target.files[0];
    // console.log(file);
    if (!file) return;
    const fileExist = imageState.imageList.filter(
      (image) => image.filename === file.name
    )[0];
    if (fileExist)
      return alert(
        "Filename already exist, choose another name or delete existing one"
      );

    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "ImageCloudinaru");
    data.append("cloud_name", "dc1jtk3jy");

    dispatch(CloudinaryDatasource.uploadImage(data));

    // const res = await fetch(
    //   "https://api.cloudinary.com/v1_1/dc1jtk3jy/video/upload",
    //   {
    //     method: "POST",
    //     body: data,
    //   }
    // );

    // const uploadedImageURL = await res.json();
    // console.log(uploadedImageURL.url);
  };

  const onDeleteFileClick = (file: FileInfo) => {
    // console.log(id);
    FirebaseImageDataSource.deleteImage(file.id);
    FirebaseImageDataSource.getImages();
  };

  const onShareFileClick = (file: FileInfo) => {
    // const currentImageRef = doc(db, "currentImage", "Image");
    // updateDoc(currentImageRef, {
    //   filename: file.filename,
    //   publicId: file.publicId,
    //   url: file.url,
    // });
    // setImage(file);
    const currentFileRef = doc(db, "currentFile", "File");
    updateDoc(currentFileRef, {
      type: "Image",
      url: file.url,
    });
    setImage(file);
  };

  return (
    <div className="h-screen w-screen flex flex-col">
      {imageState.uploading && (
        <div className="h-[90%] w-full flex justify-center items-center">
          Upload Image...
        </div>
      )}
      {!imageState.uploading && (
        <div className="h-[90%] w-full flex">
          <div className="w-[30%] p-2 flex flex-col gap-2">
            <div className="w-full h-[20%] flex gap-3 justify-center items-center border-2">
              <img src={uploadImg} alt="" className="h-[50%]" />
              <button
                onClick={() => {
                  inputFileRef.current!.click();
                }}
              >
                Choose File
              </button>
              <input
                type="file"
                className="hidden"
                onChange={onInputFileChange}
                ref={inputFileRef}
              />
            </div>
            <div className="w-full h-[78%] border-2">
              <FileListBox
                files={imageState.imageList}
                onDeleteClick={onDeleteFileClick}
                onShareClick={onShareFileClick}
              />
            </div>
          </div>
          <div className="w-[70%] p-2">
            <div className="w-full h-full border-2 flex flex-col">
              <div className="w-full flex p-2 bg-slate-700 text-white">
                {image?.filename}
              </div>

              <div
                className="w-full h-full flex bg-red-500 p-2"
                style={{
                  backgroundImage: `url(${image?.url})`,
                  backgroundSize: "100% 100%",
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
      <div className="h-[10%]">{!imageState.uploading && <Navbar />}</div>
    </div>
  );
};

export default AdminImagePage;

{
  /* <div className="w-full flex p-2 bg-slate-700 text-white">
              {imageState.currentImage
                ? imageState.currentImage.filename
                : "[No Image Shared]"}
            </div> */
}
{
  /* <div className="w-full h-full flex bg-red-500">
              <img src={imageState.currentImage?.url} alt="" />
            </div> */
}
