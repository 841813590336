import { FaShare, FaTrash } from "react-icons/fa";
import { FileInfo } from "../../domain/entities/FileInfo";

type TFileListBoxProps = {
  files: FileInfo[] | [];
  onDeleteClick: (file: FileInfo) => void;
  onShareClick: (file: FileInfo) => void;
};

export const FileListBox = (props: TFileListBoxProps) => {
  // const onItemClick = (_: any, id: any) => {
  //   props.files.map((file: FileInfo) => {
  //     document.getElementById(file.filename)!.style.backgroundColor =
  //       file.filename === id ? "blue" : "white";
  //     document.getElementById(file.filename)!.style.color =
  //       file.filename === id ? "white" : "black";
  //   });
  //   const x = props.files.filter((item: any) => item.id === id)[0];
  //   props.onFileClick(x);
  // };

  // const onDeleteIconClick = (id: string) => {
  //   console.log(id);
  // };

  return (
    <div className="h-full w-full flex flex-col overflow-y-scroll px-2">
      {props.files.map((file: FileInfo, index) => (
        <div className="flex border-b-2 p-2 justify-between" key={index}>
          <span>{file.filename}</span>
          <div className="flex gap-4">
            <FaTrash
              className="text-red-700 cursor-pointer"
              onClick={() => props.onDeleteClick(file)}
            />
            <FaShare
              className="text-green-700 cursor-pointer"
              onClick={() => props.onShareClick(file)}
            />
          </div>
        </div>
        // <span
        //   key={index}
        //   id={file.filename}
        //   onClick={(_) => onItemClick(_, file.filename)}
        //   className="cursor-pointer"
        // >
        //   {file.filename}
        // </span>
      ))}
    </div>
  );
};

export default FileListBox;

// import { FileInfo } from "../../domain/entities/FileInfo";

// type TFileListBoxProps = {
//   files: FileInfo[] | [];
//   onFileClick: (file: FileInfo) => void;
// };

// export const FileListBox = (props: TFileListBoxProps) => {
//   const onItemClick = (_: any, id: any) => {
//     props.files.map((file: FileInfo) => {
//       document.getElementById(file.filename)!.style.backgroundColor =
//         file.filename === id ? "blue" : "white";
//       document.getElementById(file.filename)!.style.color =
//         file.filename === id ? "white" : "black";
//     });
//     const x = props.files.filter((item: any) => item.id === id)[0];
//     props.onFileClick(x);
//   };

//   return (
//     <div className="h-full w-full flex flex-col overflow-y-scroll px-2">
//       {props.files.map((file: FileInfo, index) => (
//         <span
//           key={index}
//           id={file.filename}
//           onClick={(_) => onItemClick(_, file.filename)}
//           className="cursor-pointer"
//         >
//           {file.filename}
//         </span>
//       ))}
//     </div>
//   );
// };

// export default FileListBox;
