import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appSlice } from "./slices/appSlice";
import { scheduleSlice } from "./slices/scheduleSlice";
import { videoSlice } from "./slices/videoSlice";
import { imageSlice } from "./slices/imageSlice";
import { authSlice } from "./slices/authSlice";

const rootReducers = combineReducers({
  appReducer: appSlice.reducer,
  authReducer: authSlice.reducer,
  videoReducer: videoSlice.reducer,
  imageReducer: imageSlice.reducer,
  scheduleReducer: scheduleSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducers,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({ serializableCheck: true }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;