import { collection, doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../connection/firebaseConfig";
// import { socket } from "../../connection/socketManager";

const Navbar = () => {
  const navigate = useNavigate();
  // const appDbRef = collection(db, "app");
  const pageRef = doc(db, "app", "VbCQEyzjAZKTeLTCyN3r");
  const btnClass =
    "bg-slate-700 w-32 h-10 text-white cursor-pointer hover:bg-slate-600";

  const onBtnVideoClick = () => {
    // socket.emit("/gotoVideoPage");
    navigate("/adminVideo");
    updateDoc(pageRef, { page: "/adminVideo" });
  };

  const onBtnImageClick = () => {
    // socket.emit("/gotoImagePage");
    navigate("/adminImage");
    updateDoc(pageRef, { page: "/adminImage" });
  };

  const onBtnNoteClick = () => {
    // socket.emit("/gotoNotePage");
    navigate("/adminNote");
    updateDoc(pageRef, { page: "/adminNote" });
  };

  const onBtnScheduleClick = () => {
    // socket.emit("/gotoCreateSchedulePage");
    navigate("/adminCreateSchedule");
    // updateDoc(pageRef, { page: "/adminCreateSchedule" });
  };

  const onBtnHomeClick = () => {
    // socket.emit("/gotoHomePage");
    navigate("/adminHome");
    updateDoc(pageRef, { page: "/adminHome" });
  };

  return (
    <div className="h-full flex gap-4 justify-center items-center bg-slate-400">
      <button className={btnClass} onClick={onBtnHomeClick}>
        Home
      </button>
      <button className={btnClass} onClick={onBtnVideoClick}>
        Video
      </button>
      <button className={btnClass} onClick={onBtnImageClick}>
        Image
      </button>
      <button className={btnClass} onClick={onBtnNoteClick}>
        Note
      </button>
      <button className={btnClass} onClick={onBtnScheduleClick}>
        Schedule
      </button>
    </div>
  );
};

export default Navbar;
