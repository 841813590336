import { createSlice } from "@reduxjs/toolkit";
import { Schedule } from "../../../domain/entities/Schedule";
import { FileInfo } from "../../../domain/entities/FileInfo";

type ScheduleState = {
  scheduleList: Schedule[];
  schedule: Schedule;
  currentSchedule: Schedule;
  runFile: { type: string; url: string };
};

const initialAppSlice: ScheduleState = {
  scheduleList: [],
  schedule: { id: "", name: "", items: [] },
  currentSchedule: { id: "", name: "", items: [] },
  runFile: { type: "", url: "" },
};

export const scheduleSlice = createSlice({
  name: "Schedule",
  initialState: initialAppSlice,
  reducers: {
    insertScheduleItem: (state, action) => {
      state.schedule.items.push(action.payload);
    },
    setSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    setScheduleList: (state, action) => {
      state.scheduleList = action.payload;
    },
    setCurrentSchedule: (state, action) => {
      state.currentSchedule = action.payload;
    },
    setRunFile: (state, action) => {
      state.runFile = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  insertScheduleItem,
  setSchedule,
  setScheduleList,
  setCurrentSchedule,
  setRunFile,
} = scheduleSlice.actions;
// export const { insertSchedule, setName, setSchedule } = scheduleSlice.actions;
