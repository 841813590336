import { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import { FirebaseNoteDataSource } from "../../data/firebase/noteDatasource";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  ["link", "image", "video", "formula"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

const module = {
  toolbar: toolbarOptions,
};

const AdminNotePage = () => {
  const quillRef = useRef<any>(null);
  const [value, setValue] = useState("");

  const getCurrentNote = async () => {
    const note: any = await FirebaseNoteDataSource.getCurrentNote();
    console.log(note);

    setValue(note);
  };

  useEffect(() => {
    getCurrentNote();
  }, []);

  const reactQuillChange = (value: any, delta: any) => {
    setValue(value);
    FirebaseNoteDataSource.writeNote(value);
  };

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="h-[90%]">
        <ReactQuill
          value={value}
          theme="snow"
          ref={quillRef}
          onChange={reactQuillChange}
          className="h-[90%]"
          modules={module}
        />
      </div>
      <div className="h-[10%]">
        <Navbar />
      </div>
    </div>
  );
};

export default AdminNotePage;
