// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCsQLGE_i9cAbxJRyX1HFuYZsbPJjx-Wo4",
    authDomain: "sharing-dashboard-c33bd.firebaseapp.com",
    projectId: "sharing-dashboard-c33bd",
    storageBucket: "sharing-dashboard-c33bd.firebasestorage.app",
    messagingSenderId: "387690881931",
    appId: "1:387690881931:web:b814dbb05255bee550858e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);