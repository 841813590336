import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../../domain/entities/User";
import { FirebaseAuthDatasource } from "../../../data/firebase/authDatasource";
import Cookies from "universal-cookie";
const cookies = new Cookies();

type AuthState = {
  user: User | null;
  isAuth: boolean;
};

const initialAuthSlice: AuthState = {
  user: null,
  isAuth: cookies.get("auth-token"),
};

export const authSlice = createSlice({
  name: "App",
  initialState: initialAuthSlice,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(FirebaseAuthDatasource.signin.fulfilled, (state, action) => {
    //     console.log("login success");
    // //   console.log("Signin success: ", action.payload);
    // //   setUser(null);
    // });
    // builder.addCase(FirebaseAuthDatasource.signout.fulfilled, () => {
    //     console.log("Signout success");
    //     setUser(null);
    // })
  },
});

export const { setUser, setIsAuth } = authSlice.actions;
