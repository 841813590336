import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./presentation/redux/store";
import { SocketManager } from "./connection/socketManager";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./connection/firebaseConfig";
import { setTime } from "./presentation/redux/slices/appSlice";

// SocketManager.connect();

// setInterval(() => {
//   let time = new Date();
//   const h = time.getHours();
//   const m = time.getMinutes();
//   const s = time.getSeconds();
//   store.dispatch(setTime({ h, m, s }));
//   // console.log(1);
// }, 1000);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
