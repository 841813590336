import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../connection/firebaseConfig";
import { FirebaseImageDataSource } from "../../data/firebase/imageDataSource";

const ClientImageContainer = () => {
  const [imageUrl, setImageUrl] = useState("");

  const getCurrentImage = async () => {
    const f: any = await FirebaseImageDataSource.getCurrentImage();
    setImageUrl(f.url);
  };

  useEffect(() => {
    getCurrentImage();
    // const queryApp = collection(db, "currentImage");
    // onSnapshot(queryApp, (snapshot) => {
    //   snapshot.docs.map((document) => {
    //     setImageUrl(document.data().url);
    //   });
    // });
    const queryApp = collection(db, "currentFile");
    onSnapshot(queryApp, (snapshot) => {
      snapshot.docs.map((document) => {
        const data = document.data();
        if (data.type === "Image") setImageUrl(data.url);
      });
    });
  }, []);

  return (
    <div
      className="w-full h-full flex bg-red-500 p-2"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "100% 100%",
      }}
    ></div>
  );

  // return (
  //   <div className="w-full h-full flex bg-red-500">
  //     <img src={image} alt="" />

  //     {/* <img src={imageState.currentImage?.url} alt="" /> */}
  //   </div>
  // );
};

export default ClientImageContainer;
