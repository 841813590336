import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../connection/firebaseConfig";
import { store } from "../../presentation/redux/store";
import { setCurrentImage } from "../../presentation/redux/slices/imageSlice";
import { Schedule } from "../../domain/entities/Schedule";
import { FilePlaying } from "../../domain/FIlePlaying";

export class FirebaseAppDataSource {
  static getCurrentPage = async () => {
    const currentPageRef = doc(db, "app", "CurrentPage");
    const snap = await getDoc(currentPageRef);
    const currentPage = snap.data();
    console.log(currentPage);
  };

  static getCurrentFile = async () => {
    const currentFileRef = doc(db, "currentFile", "File");
    const snap = await getDoc(currentFileRef);
    const currentFile = snap.data();
    console.log(currentFile);
    return currentFile;
  };

  static setCurrentFile = async (file: FilePlaying) => {
    // const imagesCollectionRef = collection(db, "currentImage");
    const currentFileRef = doc(db, "currentFile", "File");
    updateDoc(currentFileRef, file);

    // console.log(snap.data());
  };
  // static getCurrentImage = async () => {
  //   const docRef = doc(db, "app", "C1G8b733pw3xhrSWzN1F");
  //   const docSnap = await getDoc(docRef);
  //   if (docSnap.exists()) {
  //     return docSnap.data().currentImage;
  //     //   store.dispatch(setCurrentImage(docSnap.data().currentImage));
  //   } else {
  //     console.log("No document");
  //     return null;
  //   }
  // };
  // static getSchedules = async () => {
  //   const schedulesCollectionRef = collection(db, "schedules");
  //   const querySnapshot = await getDocs(schedulesCollectionRef);
  //   const scheduleList: Schedule[] = [];
  //   querySnapshot.forEach((doc: any) => {
  //     const schedule = doc.data();
  //     schedule.id = doc.id;
  //     scheduleList.push(schedule);
  //   });
  //   store.dispatch(setScheduleList(scheduleList));
  // };
  // static deleteSchedule = async (id: string) => {};
}
